import React, { useEffect, useState } from "react";
import "../../assets/styles/BBSExcel.css";
import Button from "../../utils/Button";
import * as XLSX from "xlsx";
import SmartFabExcelConverter from "./SmartFabExcelConverter";
import ExcelTemplate from "../../assets/files/SmartFabExcel_template.xlsx";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { saveAs } from "file-saver";
import { fetchGradesSF } from "../../redux-tookit/slices/gradeMasterSlice";

const SmartFabExcel = (props) => {
    const { isOpen, onClose, orderId, selectedRowIndex, combId } = props;
    console.log(orderId);

    const dispatch = useDispatch();

    const [excelJson, setExcelJson] = useState([]);
    const [process, setProcess] = useState(false);
    const [isConverter, setIsConverter] = useState(false);
    const [cleanTags, setCleanTags] = useState(false);
    const [gradeList, setGradeList] = useState([]);

    const handleUploadComplete = () => {
        setExcelJson([]);
        setIsConverter(false);
        onClose();
    };

    const excelToJson = (e) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const data = event.target.result;
                    console.log(data);

                    const workbook = XLSX.read(data, { type: "binary" });
                    const sheetName = workbook.SheetNames[0]; // Assuming the data is in the first sheet
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                        header: 1,
                    });
                    resolve(jsonData);
                } catch (error) {
                    toast.error(`${error}`);
                    e.target.value = null;
                }
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsBinaryString(e.target.files[0]);
        });
    };

    const handleFileUpload = (e) => {
        // console.log(e.target.files[0].type);
        // console.log(e.target.value);
        setProcess(true);
        if (!e.target.value.endsWith(".xlsx")) {
            toast.error("File not in excel format");
            e.target.value = null;
            setProcess(false);
            return;
        }
        if (!e.target.value.includes(combId)) {
            toast.error("File name does not have correct Order id");
            e.target.value = null;
            setProcess(false);
            return;
        }
        excelToJson(e)
            .then((jsonData) => {
                console.log(jsonData); // Do something with the JSON data
                if (jsonData.length === 0) {
                    toast.error("Excel is empty.");
                    e.target.value = null;
                    setProcess(false);
                    return;
                } else {
                    for (let index = 1; index <= jsonData.length; index++) {
                        const data = jsonData[index];
                        if (data[0] === "" || data[0] === undefined) {
                            // setProcess(false);
                            console.log("hit 1");

                            break;
                        }

                        for (let i = 1; i <= 23; i++) {
                            if (data[i] < 0) {
                                toast.error(
                                    "The parameters contain negative values"
                                );
                                e.target.value = null;
                                setProcess(false);
                                return;
                            }
                            if (data[i] === "" || data[i] === undefined) {
                                toast.error(
                                    "There is invalid info in the excel. Please check and upload again"
                                );
                                e.target.value = null;
                                setProcess(false);
                                return;
                            }
                        }
                    }
                }
                console.log(jsonData);
                setExcelJson(jsonData);
                setProcess(false);
                // setIsConverter(true)
            })
            .catch((error) => {
                setProcess(false);
                console.error("Error converting Excel to JSON:", error);
            });
    };

    // for reading and downloading from saved excel

    const readExcelFile = async () => {
        const response = await fetch(ExcelTemplate);
        const data = await response.arrayBuffer();
        const workbook = XLSX.read(data, { type: "array", bookType: "xlsx" });
        return workbook;
    };

    const saveExcelFile = (workbook) => {
        const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([wbout], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `BBSSheet_${combId}.xlsx`);
        // const url = URL.createObjectURL(blob);
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = `BBSSheet_${combId}.xlsm`;
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);
    };

    const handleDownload = async () => {
        const workbook = await readExcelFile();
        // modifyExcelFile(workbook);
        saveExcelFile(workbook);
    };

    useEffect(() => {
        dispatch(fetchGradesSF()).then((response) => {
            setGradeList(response.payload.labeledData);
        });
    }, [orderId]);

    return (
        isOpen && (
            <div className="popup">
                {!isConverter && (
                    <div className="popup-content">
                        <div
                            style={{
                                color: "#138EE0",
                                fontWeight: "700",
                                padding: "10px",
                                fontSize: "1.25rem",
                            }}
                        >
                            BBS Excel Sheet for Order Id : {combId}
                        </div>

                        <div className="card1">
                            <div className="hr" style={{ padding: "0 15px" }}>
                                Download BBS Excel Sheet
                            </div>
                            <hr></hr>
                            <div style={{ padding: "10px" }}>
                                <div
                                    style={{
                                        padding: "5px",
                                        fontWeight: "normal",
                                    }}
                                >
                                    Click to download Excel sheet for BBS entry
                                </div>

                                <a
                                    href={ExcelTemplate}
                                    download={`BBSExcel_${combId}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        label="Download"
                                        className={`blue ${
                                            selectedRowIndex === null ||
                                            selectedRowIndex === undefined
                                                ? "disabled"
                                                : ""
                                        }`}
                                    />
                                </a>
                            </div>
                        </div>

                        <div className="card1">
                            <div style={{ padding: "0 15px" }}>
                                Upload BBS Excel Sheet
                            </div>
                            <hr></hr>
                            <div style={{ padding: "10px" }}>
                                <input
                                    type="file"
                                    accept=".xlsx"
                                    style={{ padding: "5px" }}
                                    onChange={(e) => handleFileUpload(e)}
                                />
                                {process && (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            className="circle-loader2"
                                            style={{ position: "relative" }}
                                        ></div>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }}
                                        >
                                            Extracting
                                            <div className="point1">.</div>
                                            <div className="point2">.</div>
                                            <div className="point3">.</div>
                                        </div>
                                    </div>
                                )}
                                {/* <div>
                                <input
                                    type="checkbox"
                                    checked={cleanTags}
                                    onClick={() => {
                                        setCleanTags(!cleanTags);
                                    }}
                                    name="cleanPrev"
                                    id="cleanPrev"
                                />
                                Clean previous tag
                            </div> */}
                                {!process && (
                                    <Button
                                        label="Upload"
                                        className="blue"
                                        onClick={() => setIsConverter(true)}
                                        disabled={excelJson}
                                        message="Select a file for upload"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="button">
                            <Button
                                label="Close"
                                className="blue"
                                onClick={onClose}
                            />
                        </div>
                    </div>
                )}
                {isConverter && (
                    <SmartFabExcelConverter
                        dataArray={excelJson}
                        combId={combId}
                        orderId={orderId}
                        onClose={handleUploadComplete}
                        cleanTags={cleanTags}
                        gradeList={gradeList}
                    />
                )}
            </div>
        )
    );
};

export default SmartFabExcel;
