import React, { useEffect, useState } from "react";
import "../../assets/styles/BBSExcel.css";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { postSmartFabExcelBbs } from "../../redux-tookit/slices/smartFab/productionParameterSlice";
import { fetchOrderSF } from "../../redux-tookit/slices/orderSlice";
import Loader from "../Loader/Loader";

const SmartFabExcelConverter = (props) => {
    const { dataArray, orderId, combId, cleanTags, gradeList, onClose } = props;

    // const [loaderValue, setLoaderValue] = useState(0);

    const dispatch = useDispatch();

    // const handleLoaderValue = (value) => {
    //     setLoaderValue(() => value);
    //     requestAnimationFrame(() => {});

    //     console.log(value);
    // };

    const getGradeInfo = (materialName) => {
        const foundItem = gradeList.find(
            (item) => item.material === materialName
        );
        console.log(materialName, gradeList, foundItem);

        return {
            gradeId: foundItem.gradeId,
            smartFabProductTypeId: foundItem.smartFabProductTypeId,
        };
    };

    const dataConverter = async () => {
        let tempFinalData = [];

        const startFrom = 1; // Start processing from index 5

        const arrayLength = dataArray.length;

        const processArray = async () => {
            // dataArray.slice(start, end).map((data, rowIndex) =>
            for (let index = startFrom; index <= arrayLength; index++) {
                const data = dataArray[index];
                console.log(data[0]);

                if (data[0] === "" || data[0] === undefined) {
                    return tempFinalData;
                }

                console.log(data, dataArray);

                const gradeInfo = getGradeInfo(data[2]);

                for (let i = 4; i <= 23; i++) {
                    if (data[i] < 0) {
                        return -1;
                    }
                }

                tempFinalData.push({
                    orderId: orderId,

                    shape: data[1],
                    // gradeId: 1,
                    // smartFabProductTypeId: 1,
                    ...gradeInfo,
                    finish: data[3],

                    mwDia: data[4],
                    cwDia: data[5],
                    mwSpacing: data[6],
                    cwSpacing: data[7],
                    length: data[8],
                    width: data[9],
                    mwOH1: data[10],
                    mwOH2: data[11],
                    cwOH1: data[12],
                    cwOH2: data[13],
                    noOfMw: data[14],
                    noOfCw: data[15],
                    sheetWeight: data[16],
                    totalSheets: data[17],
                    totalWeight: data[18],
                    weldedLength: data[20],
                    weldedWidth: data[21],
                    mwTotalLength: data[22],
                    cwTotalLength: data[23],
                });
            }

            return tempFinalData;
        };

        try {
            const finalData = await processArray();
            console.log(finalData);

            const l = finalData.length;
            // let error = false;

            for (let index = 0; index < l; index++) {
                Object.keys(finalData[index]).forEach((header) => {
                    if (
                        finalData[index][header] < 0 ||
                        finalData[index][header] === ""
                    ) {
                        // error = true;
                        toast.error(
                            "There is invalid info in the excel. Please check and upload again"
                        );
                        onClose();
                    }
                });
            }

            if (finalData.length === 0) {
                toast.error("No data in excel");
                onClose();
            } else {
                console.log("Done");
                console.log(finalData);
                const newRow = finalData;
                dispatch(postSmartFabExcelBbs({ newRow, cleanTags })).then(
                    () => {
                        dispatch(fetchOrderSF());
                        onClose();
                    }
                );
            }

            return finalData;
        } catch (error) {
            console.error("Error processing chunks:", error);
            // Handle error here
            return [];
        }
    };

    useEffect(() => {
        console.log("start");

        console.log(dataArray);

        dataConverter();
    }, []);

    return (
        <>
            <Loader />
            {/* :""} */}
            <div style={{ display: "none" }}></div>
        </>
    );
};

export default SmartFabExcelConverter;
