import { Image, Page, StyleSheet, Text, View, Font } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import HeaderRight from '../../assets/images/icons/readybuild.png';
import { dateChanger } from '../reusableFunctions';
import font from "../../assets/fonts/OpenSans-Variable.ttf"
import font2 from "../../assets/fonts/OpenSans-Bold.ttf"
import font3 from "../../assets/fonts/OpenSans-MediumItalic.ttf"
Font.register({ family: 'Roboto', src: '../../assets/fonts/robots.txt' });

const serviceCenterName = sessionStorage.getItem('serviceCenterName');


const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    // alignItems: "center",
    // flexWrap: 'wrap',
    border: '1px solid black',
    width: '100%',
    height: '100%',
    padding: '2%',
  },
  bbsInfo: {
    flexDirection: 'column',
    fontSize: '9',
    // border: '1px solid black',
    width: '100%',
    alignItems: "center",
    height: '37%'
  },
  bbsHeader: {
    flexDirection: 'row',
    // border: '1px solid black',
    width: '100%',
    height: '15%',
  },
  bbsParty: {
    flexDirection: 'row',
    alignSelf: "center",
    width: '95%',
    marginTop: '10px',
    marginBottom: '10px',
    borderBottom: '1px solid black',
    // border: '1px solid black',
    height: '42%'
  },
  belowParty: {
    flexDirection: 'column',
    width: '95%',
    // border: '1px solid black'
    height: '43%'
  },
  extraDetails: {
    flexDirection: 'row',
    height: '20%',
    // border: '1px solid red',
    width: '100%'
  },
  diaWeight: {
    flexDirection: 'row',
    height: '70%',
    marginTop: '10px',
    // border: '1px solid yellow',
    width: '100%'
  },
  diaBoxMain: {
    flexDirection: 'column',
    width: '50px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  diaBoxLeftHead1: {
    flexDirection: 'column',
    width: '70px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid black'
  },
  diaBoxLeftHeadBelow: {
    flexDirection: 'column',
    width: '70px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid black',
    borderTop: '0px'
  },
  diaBoxTopHead1: {
    flexDirection: 'column',
    width: '50px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid black',
    borderLeft: '0px'
  },
  diaBoxData: {
    flexDirection: 'column',
    width: '50px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid black',
    borderLeft: '0px',
    borderTop: '0px'
  },
  diaBox: {
    flexDirection: 'column',
    width: '50px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid black'
  },
  elementHeader: {
    borderBottom: '1px solid black',
    fontSize: '12',
    padding: '5px 0'
  }
})

const BbsInfo = (props) => {

  const { parties, partItem, orderInfo, diaWiseWeight, serviceCenterLogo, diaWiseThreads, diaWiseHalfThread, diaWiseFullThread, lengthDeduction } = props;
  const l = partItem.length;
  let totalThreads = 0;
  let totalWeight = 0;
  let totalHalfThread = 0;
  let totalFullThread = 0;

  Object.keys(diaWiseThreads).forEach(key => {
    totalThreads += diaWiseThreads[key];
    totalWeight += diaWiseWeight[key];
    totalHalfThread += diaWiseHalfThread[key];
    totalFullThread += diaWiseFullThread[key];
  })

  console.log(serviceCenterName);

  return (
    <View style={styles.bbsInfo}>
      <View style={styles.bbsHeader}>
        <View style={{ width: '15%', height: '100%', border: '1px solid black' }}>
          {
            serviceCenterLogo != null && serviceCenterLogo !== '' ?
              <Image src={serviceCenterLogo} style={{ height: '100%' }} alt={serviceCenterName}></Image>
              : <Text style={{ fontSize: '15' }}>{serviceCenterName}</Text>
          }
        </View>
        <View style={{ flexDirection: 'column', width: '70%', height: '100%', border: '1px solid black' }}>
          <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
            <Text style={{ textDecoration: 'underline', fontSize: '11', fontFamily: 'OpenSans-Bold' }}>Bar Bending Shedule</Text>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
              <Text style={{ fontWeight: '600', fontSize: '11', width: '40%' }}><Text style={{ fontFamily: 'OpenSans-Bold' }}>Order Id: </Text>{orderInfo.combId || ''}</Text>
              <Text style={{ fontWeight: '600', fontSize: '11', width: '50%', }}>Ref: {orderInfo.orderRemarks || ''}</Text>
            </View>
          </View>
        </View>
        <View style={{ width: '15%', height: '100%', border: '1px solid black' }}>
          <Image src={HeaderRight} style={{ height: '100' }}></Image>
        </View>
      </View>
      <View style={styles.bbsParty}>
        <View style={{ width: '33.33%', height: '100%', gap: '5px', paddingLeft: '15px' }}>
          <Text style={{ textDecoration: 'underline', fontWeight: '600', fontSize: '11', fontFamily: 'OpenSans-Bold' }}>Sold to Party</Text>
          <Text>{parties.soldToParty || ''}</Text>
          <Text>{parties.soldToPartyAddress || ''}</Text>
          <Text>{parties.soldToPartyEmail || ''}</Text>
          <Text>{parties.soldToPartyPhoneNo || ''}</Text>
        </View>
        <View style={{ width: '33.33%', height: '100%', gap: '5px', paddingLeft: '15px' }}>
          <Text style={{ textDecoration: 'underline', fontWeight: '600', fontSize: '11', fontFamily: 'OpenSans-Bold' }}>Bill to Party</Text>
          <Text>{parties.billToParty || ''}</Text>
          <Text>{parties.billToPartyAddress || ''}</Text>
          <Text>{parties.billToPartyEmail || ''}</Text>
          <Text>{parties.billToPartyPhoneNo || ''}</Text>
        </View>
        <View style={{ width: '33.33%', height: '100%', gap: '5px', paddingLeft: '15px' }}>
          <Text style={{ textDecoration: 'underline', fontWeight: '600', fontSize: '11', fontFamily: 'OpenSans-Bold' }}>Ship to Party</Text>
          <Text>{parties.shipToParty || ''}</Text>
          <Text>{parties.shipToPartyAddress || ''}</Text>
          <Text>{parties.shipToPartyEmail || ''}</Text>
          <Text>{parties.shipToPartyPhoneNo || ''}</Text>
        </View>
      </View>
      <View style={styles.belowParty}>
        <View style={styles.extraDetails}>
          <View style={{ width: '33.33%', paddingLeft: '15px' }}>
            <Text>No. of Bar Marks: {l || ''}</Text>
          </View>
          <View style={{ width: '33.33%', paddingLeft: '15px' }}>
            <Text>Weight (T): {Math.round(totalWeight * 1000) / 1000}</Text>
          </View>
          <View style={{ width: '33.33%', paddingLeft: '15px' }}>
            <Text>Order Date: {orderInfo.createdTs || ''}</Text>
            <Text>Delivery Date: {dateChanger(orderInfo.deliveryDate) || ''}</Text>
          </View>
        </View>
        <View style={styles.diaWeight}>
          <View style={styles.diaBoxMain}>
            <View style={styles.diaBoxLeftHead1}>
              <Text style={{ fontFamily: 'OpenSans-Bold' }}>Diameter</Text>
            </View>
            <View style={styles.diaBoxLeftHeadBelow}>
              <Text style={{ fontFamily: 'OpenSans-Bold' }}>Wt.(T)</Text>
            </View>
            {/* <View style={styles.diaBoxLeftHeadBelow}>
              <Text style={{ fontFamily: 'OpenSans-Bold' }}>Threads</Text>
            </View> */}
            {(totalHalfThread * 1) !== 0 && <View style={styles.diaBoxLeftHeadBelow}>
              <Text style={{ fontFamily: 'OpenSans-Bold' }}>Half Threads</Text>
            </View>}
            {(totalFullThread * 1) !== 0 && <View style={styles.diaBoxLeftHeadBelow}>
              <Text style={{ fontFamily: 'OpenSans-Bold' }}>Full Threads</Text>
            </View>}
          </View>
          {Object.keys(diaWiseWeight).map((key) => (
            <View style={styles.diaBoxMain}>
              <View style={styles.diaBoxTopHead1}>
                <Text style={{ fontFamily: 'OpenSans-Bold' }}>{key || ''}</Text>
              </View>
              <View style={styles.diaBoxData}>
                <Text>{Math.floor(diaWiseWeight[key] * 1000) / 1000 || ''}</Text>
              </View>
              {/* <View style={styles.diaBoxData}>
                <Text>{Math.floor(diaWiseThreads[key] * 1000) / 1000}</Text>
              </View> */}
              {(totalHalfThread * 1) !== 0 && <View style={styles.diaBoxData}>
                <Text>{Math.floor(diaWiseHalfThread[key] * 1000) / 1000}</Text>
              </View>}
              {(totalFullThread * 1) !== 0 && <View style={styles.diaBoxData}>
                <Text>{Math.floor(diaWiseFullThread[key] * 1000) / 1000}</Text>
              </View>}
            </View>
          ))}
          <View style={styles.diaBoxMain}>
            <View style={styles.diaBoxTopHead1}>
              <Text style={{ fontFamily: 'OpenSans-Bold' }}>Total</Text>
            </View>
            <View style={styles.diaBoxData}>
              <Text>{Math.floor(totalWeight * 1000) / 1000 || ''}</Text>
            </View>
            {/* <View style={styles.diaBoxData}>
              <Text>{totalThreads}</Text>
            </View> */}
            {(totalHalfThread * 1) !== 0 && <View style={styles.diaBoxData}>
              <Text>{totalHalfThread}</Text>
            </View>}
            {(totalFullThread * 1) !== 0 && <View style={styles.diaBoxData}>
              <Text>{totalFullThread}</Text>
            </View>}
          </View>
        </View>
      </View>
    </View>
  )
}

const TableHeader = () => {

  let tableData = [{ label: "Sl No", width: '4%' },
  { label: "Bar Mark Id", width: '14%' },
  // { label: "Element", width: '12%' },
  { label: "Tag Desc.", width: '12%' },
  { label: "Shape", width: '18%' },
  { label: "Dia (mm)", width: '5%' },
  { label: "Pin Dia (mm)", width: '5%' },
  { label: "Length (mm)", width: '8%' },
  { label: "Total Pieces", width: '8%' },
  { label: "Bundles x (pieces)", width: '8%' },
  { label: "Threads (half /full)", width: '8%' },
  { label: "Weight (T)", width: '10%' }
  ]


  return (
    <View style={{ flexDirection: 'row', height: '40px', width: '100%' }}>
      {tableData.map((data, tableIndex) => (
        <View style={{
          flexDirection: 'column',
          width: data.width,
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid black',
          fontSize: '9',
          fontFamily: 'OpenSans-Bold',
          borderLeft: `${tableIndex === 0 ? '1px' : '0px'}`
        }}>
          <Text style={{}}>{data.label || ''}</Text>
        </View>
      ))}
    </View>
  )
}

const PartItemMapper = ({ partItem, index, lengthDeduction }) => {

  let tableData = [
    { key: "index", width: '4%', },
    { key: "barMarkId", width: '14%' },
    // { key: "orderItemName", width: '12%' },
    { key: "tagDescription", width: '12%' },
    { key: "shape", width: '18%' },
    { key: "diameter", width: '5%' },
    { key: "pinDia", width: '5%' },
    { key: `${lengthDeduction ? 'productionLength' : 'inventoryLength'}`, width: '8%' },
    { key: "totalNoOfPieces", width: '8%' },
    { key: "bundleQuantity", width: '8%' },
    { key: "threads", width: '8%' },
    { key: `${lengthDeduction ? 'productionWeight' : 'inventoryWeight'}`, width: '10%' }
  ]

  const noOfFullBundles = Math.floor(partItem.totalNoOfPieces / partItem.bundleQuantity)


  const pcsPerBundle = `${noOfFullBundles !== 0 ? `${noOfFullBundles}x(${partItem.bundleQuantity})` : ''}${partItem.totalNoOfPieces % partItem.bundleQuantity !== 0 ? `\n1x(${partItem.totalNoOfPieces % partItem.bundleQuantity})` : ''}`



  const url = String.fromCharCode.apply(null, new Uint8Array(partItem.image.data));
  // console.log(url);

  return (
    <View wrap={false} style={{ flexDirection: 'row', height: '79px', width: '100%', marginBottom: `${'0'}` }}>

      {tableData.map((data, partIndex) => (
        <>

          <View style={{
            flexDirection: 'column',
            width: data.width,
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid black',
            fontSize: '9',
            borderLeft: `${partIndex === 0 ? '1px' : '0px'}`,
            borderTop: `${'0px'}`

          }}>
            {/* TT-${partItem[data.key]} */}
            {
              data.key === 'index' ? <Text style={{ fontWeight: "bold" }}>{index + 1 || ''}</Text> :
                data.key === 'shape' ? <Image src={url} style={{ width: '100%' }}></Image> :
                  data.key === 'bundleQuantity' ? <Text style={{ fontWeight: "bold", lineHeight: '1.5' }}>{pcsPerBundle}</Text> :
                    data.key === 'threads' ?
                      <Text style={{ fontWeight: "bold", textAlign: 'center' }}>{`HT-${partItem.totalNoOfPieces * partItem.halfThread || '0'}
FT-${partItem.totalNoOfPieces * partItem.fullThread || '0'}`}</Text> :
                      <Text style={{ fontWeight: "bold" }}>{partItem[data.key]}</Text>
            }

          </View>
        </>
      ))}
    </View>
  )
}

//main function ##############################################################################################

const BbsTabular = (props) => {
  const { partItem, orderInfo, parties, diaValues, serviceCenterLogo, lengthDeduction } = props;

  let diaWiseWeight = {}
  let diaWiseThreads = {}
  let diaWiseHalfThread = {}
  let diaWiseFullThread = {}

  let diaWeight = {};
  let diaThreads = {};
  let diaHalfThread = {};
  let diaFullThread = {};

  console.log(partItem);


  Font.register({
    family: "OpenSans", format: "truetype", src: font
  });

  Font.register({
    family: "OpenSans-Bold", format: "truetype", src: font2
  });

  Font.register({
    family: "OpenSans-Italic", format: "truetype", src: font3
  });


  diaWeight = {};
  diaThreads = {};
  diaHalfThread = {};
  diaFullThread = {};

  diaValues.forEach((dia) => {
    const header = `T${dia}`;
    let weightSum = 0;
    let threadSum = 0;
    let halfThreadSum = 0;
    let fullThreadSum = 0;
    partItem.forEach((item) => {
      if (item.diameter * 1 === dia * 1) {
        { lengthDeduction ? weightSum += item.productionWeight : weightSum += item.inventoryWeight };
        threadSum += item.threads || 0;
        halfThreadSum += item.halfThread * item.totalNoOfPieces || 0;
        fullThreadSum += item.fullThread * item.totalNoOfPieces || 0
      }
    })
    diaWeight = { ...diaWeight, [header]: weightSum }
    diaThreads = { ...diaThreads, [header]: threadSum }
    diaHalfThread = { ...diaHalfThread, [header]: halfThreadSum }
    diaFullThread = { ...diaFullThread, [header]: fullThreadSum }

  })
  console.log(diaHalfThread, diaFullThread);
  // console.log(diaWeight);
  diaWiseWeight = { ...diaWiseWeight, ...diaWeight }
  diaWiseThreads = { ...diaWiseThreads, ...diaThreads }
  diaWiseHalfThread = { ...diaWiseHalfThread, ...diaHalfThread }
  diaWiseFullThread = { ...diaWiseFullThread, ...diaFullThread }

  let elementName = '';

  return (
    <>
      <Page size="A4" style={styles.page}>
        <Text style={{ textAlign: 'right', fontSize: '8' }} render={({ pageNumber, totalPages }) => (
          `Page ${pageNumber} of ${totalPages}`
        )} fixed />

        <View render={({ pageNumber }) => {
          return pageNumber !== 1 && (<>
            <TableHeaderFixed />
          </>)

        }} fixed />

        <BbsInfo parties={parties} partItem={partItem} orderInfo={orderInfo} diaWiseWeight={diaWiseWeight} serviceCenterLogo={serviceCenterLogo} diaWiseThreads={diaWiseThreads} diaWiseHalfThread={diaWiseHalfThread} diaWiseFullThread={diaWiseFullThread} lengthDeduction={lengthDeduction} />

        <View style={{
          flexDirection: 'column',
          width: '100%',
          alignItems: "center"
        }}>

          <TableHeader />

        </View>

        {partItem.map((element, index) => {
          elementName = element.orderItemName;
          return (
            <View wrap={false}>

              {
                index !== 0 ? partItem[index].orderItemName !== partItem[index - 1].orderItemName || (index - 5) % 9 === 0 ?
                  <View style={styles.elementHeader}><Text>Element: {partItem[index].orderItemName}</Text></View> :
                  <View /> :
                  <View style={styles.elementHeader}><Text>Element: {partItem[index].orderItemName}</Text></View>
              }

              <PartItemMapper partItem={element} index={index} lengthDeduction={lengthDeduction} />
            </View>
          )
        }
        )}
        <View style={{ position: 'absolute', bottom: '0', margin: '1%', padding: '4px 0', borderTop: '1px solid #d5d5d5', width: '100%' }} fixed>
          <Text style={{ fontSize: '8', fontFamily: "OpenSans-Italic" }} fixed >
            All shape dimensions are in 'mm' and must be measured outer to outer.
          </Text>
        </View>
      </Page>
    </>
  )
}

export default BbsTabular;

const TableHeaderFixed = () => {

  let tableData = [
    { label: "Sl No", width: '4%' },
    { label: "Bar Mark Id", width: '14%' },
    // { label: "Element", width: '12%' },
    { label: "Tag Desc.", width: '12%' },
    { label: "Shape", width: '18%' },
    { label: "Dia (mm)", width: '5%' },
    { label: "Pin Dia (mm)", width: '5%' },
    { label: "Length (mm)", width: '8%' },
    { label: "Total Pieces", width: '8%' },
    { label: "Bundles x (pieces)", width: '8%' },
    { label: "Threads", width: '8%' },
    { label: "Weight (T)", width: '10%' }
  ]

  return (
    <View style={{ flexDirection: 'row', height: '40px', width: '100%' }}>
      {tableData.map((data, tableIndex) => (
        <View style={{
          flexDirection: 'column',
          width: data.width,
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid black',
          fontSize: '9',
          fontFamily: 'OpenSans-Bold',
          borderLeft: `${tableIndex === 0 ? '1px' : '0px'}`
        }}>
          <Text style={{}}>{data.label || ''}</Text>
        </View>
      ))}
    </View>
  )
}